/* eslint-disable max-len */
import styled from 'styled-components'
import { textLink } from '@/styles/links'
import Button from './Button'
import Stack from './layout/Stack'

export default function PortugalClosing() {
  return (
    <Stack
      $between={ 2 }
    >
      <StyledIlli>
        { /* eslint-disable-next-line @next/next/no-img-element */ }
        <img 
          src="https://d2q4aqnr9moxpw.cloudfront.net/brixelPt/brixie-dog-steps.svg" 
          alt="Hero illustration" 
        />
      </StyledIlli>
      <StyledContent>
        <h1>Obrigada e adeus da Brixel</h1>
        <p>Caros clientes,</p>
        <p>É com muita tristeza e após uma cuidadosa reflexão e avaliação estratégica que tomámos a decisão de nos retirarmos do mercado português no final de Junho de 2023.</p>
        <p>Esta difícil decisão resulta do nosso desejo de nos focarmos no nosso mercado prioritário, a Suíça, onde podemos concentrar mais recursos e serviços aos nossos clientes. Acreditamos que, ao redireccionar os esforços e consolidar as nossas operações, podemos continuar a ter resultados excepcionais e manter o nosso compromisso com a excelência.</p>
        <p>Gostaríamos de expressar a nossa mais sincera gratidão a todos os nossos clientes em Portugal. A vossa confiança e apoio têm sido inestimáveis ao longo do nosso tempo no mercado português. Agradecemos a oportunidade de vos ter servido e esperamos que os nossos serviços tenham ido ao encontro e/ou excedido as vossas expectativas.</p>
        <p>Embora a nossa presença em Portugal esteja a chegar ao fim, queremos assegurar-vos que continuamos empenhados em prestar serviços de excelência aos nossos clientes na Suíça e não só. A nossa equipa trabalhará de forma diligente para assegurar uma transição suave para quaisquer projectos ou serviços ainda em curso.</p>
        <p>Se tiver alguma dúvida ou precisar de mais assistência, não hesite em contactar a nossa equipa através do <a href="mailto:ola@brixel.pt">ola@brixel.pt</a>. Estamos aqui para ajudar e fornecer todo o apoio necessário durante esta transição.</p>
        <p>O nosso agradecimento e desejos de felicidades nos seus futuros projectos. </p>
        <p>Com os melhores cumprimentos, </p>
        <p>A sua equipa Brixel </p>
      </StyledContent>
      <Button
        href="https://brixel.ch"
      >
        Visite a Brixel Suíça
      </Button>
    </Stack>
  )
}

const StyledIlli = styled.div`
  > img {
    max-width: 100%;
  }
`
  
const StyledContent = styled.div`
  h1 {
    max-width: 30rem;
    font-size: ${ p => p.theme.typo.sizes.hero };
    line-height: 1.14;
  }

  p {
    font-size: ${ p => p.theme.typo.sizes.dropped };
    line-height: 1.5;
  }

  a {
    ${ p => textLink( p.theme ) }
  }
`
