import Container from '@/components/layout/Container'
import PortugalClosing from '@/components/PortugalClosing'
import Stack from '@/components/layout/Stack'
import CompanyFooterPortugal from '@/components/CompanyFooterPortugal'
import AppChrome from '@/components/AppChrome'

export { getServerSideProps } from '../../graphql/homepage'

export default function BrixelHomepagePage() {
  return (
    <div>
      <AppChrome>
        <Container $maxWidth="650px">
          <Stack
            $top={ 4 }
            $bottom={ 8 }
          >
            <PortugalClosing />
          </Stack>
        </Container>
        <CompanyFooterPortugal />
      </AppChrome>
    </div>
  )
}
