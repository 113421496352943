import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { useCraft } from '../lib/craft'
import BrixelLogo from './BrixelLogo'
import Container from './layout/Container'
import Stack from './layout/Stack'
import Link from './Link'

export default function CompanyFooterPortugal() {
  const craftData = useCraft()
  const companyTitle = craftData?.pageProps?.companyInfo?.companyTitle
  const companyAddress = craftData?.pageProps?.companyInfo?.companyAddress

  return (
    <StyledCompanyFooter>
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
        >
          <StyledGrid>
            <StyledLogoCopyright>
              <StyledLogo>
                <Link href="https://brixel.ch">
                  <BrixelLogo />
                </Link>
              </StyledLogo>
              <StyledCopyright>
                © 2016-{ new Date().getFullYear() }{ ' ' }
                <FormattedMessage
                  description="All rights reserved"
                  defaultMessage="All rights reserved"
                  id="8o9EVS"
                />.
              </StyledCopyright>
            </StyledLogoCopyright>
            { ( companyTitle || companyAddress ) && (
              <StyledCompanyInfo>
                <h4>{ companyTitle }</h4>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={ { __html: companyAddress } }
                />
              </StyledCompanyInfo>
            ) }
          </StyledGrid>
        </Stack>
      </Container>
    </StyledCompanyFooter>
  )
}

const StyledCompanyFooter = styled.div`
  border-top: 1px solid ${ p => p.theme.colors.grey300 };
`

const StyledGrid = styled.div`
  display: grid;
  
  margin: var( --grid-gutter-offset );

  > * {
    margin: var( --grid-gutter-item );
  }

  @media ( min-width: 900px ) {
    grid-template-columns: 4fr 4fr 4fr 3fr;
  }

  @media ( min-width: 1200px ) {
    grid-template-columns: 7fr 3fr 3fr 2fr;
  }

  @media ( min-width: 1400px ) {
    grid-template-columns: 9fr 2fr 2fr 2fr;
  }
`

const StyledLogoCopyright = styled.div`
  display: flex;
  flex-direction: column;

  > *:first-child {
    margin-bottom: auto;
  }
`

const StyledLogo = styled.div`
  max-width: 100px;
  
  svg {
    max-width: 100%;
  }
`

const StyledCopyright = styled.div`
  color: ${ p => p.theme.colors.grey400 };
`

const StyledCompanyInfo = styled.div`
  h4 {
    font-weight: 600;
    margin: 0;
  }

  p {
    margin: 0;
  }
`
